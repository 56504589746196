// 导出入口模块类型
export { AppEntry } from "./core/entry";

// 导出路由注册模块类型
export { AppRouteMap } from "./core/routes";

// 导出国际化模块，以及其类型
export { i18n, I18NInitOptions, I18NTranslation } from "./core/i18n";

// 导出 history，支持 React Router
export { useHistory, withHistory } from "./core/history/history-context";

// 导出 useBeforeDestroy hooks
export { useBeforeDestroy } from "./core/beforeDestroy";

// 导出 useDocumentTitle Hooks
export { useDocumentTitle } from "./bridge/title";

// 导出 SDKLoader
export { SDKLoader } from "./bridge/sdk/SDKLoader";

// 导出地域组件
export * from "./components/Region";

// 导出 app 命名空间
import * as app from "./core/app";
export { app };
