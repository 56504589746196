import { _appUtil } from "../_bridge";

let loginUin = 0;
let ownerUin = 0;

const loginInfo = window.LOGIN_INFO;
if (loginInfo) {
  loginUin = loginInfo.loginUin;
  ownerUin = loginInfo.ownerUin;
}

const STO_KEY = `tea_last_rid_${loginUin}_${ownerUin}`;

export function getLastRegionId() {
  let regionId: number = Number(localStorage.getItem(STO_KEY));

  if (!regionId) {
    // 从控制台老逻辑获取
    regionId = Number(_appUtil.getRegionId());
  }

  return regionId || -1;
}

export function setLastRegionId(regionId: number) {
  _appUtil.setRegionId(Number(regionId));
  localStorage.setItem(STO_KEY, String(regionId));
}
