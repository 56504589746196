export class FrequencyLimiter {
  private name: string;
  private timer: number;

  // 当前调用次数
  private times: number;

  // 时间限时
  private timeout: number;
  // 次数限制
  private limitTimes: number;
  // 最高次数限制，超出将拒绝后续所有执行
  private tenseLimitTimes: number;

  // 是否已经超出最高限制
  private tense: boolean;
  // 是否已经输出告警
  private hasOutput: boolean;

  constructor({
    name = "",
    limitTimes = 10,
    tenseLimitTimes = 100,
    timeout = 10 * 1000,
  }) {
    this.times = 0;
    this.name = name;
    this.timeout = timeout;
    this.limitTimes = limitTimes;
    this.tenseLimitTimes = tenseLimitTimes;
  }

  public exec(fn: Function) {
    if (this.tense) {
      return;
    }

    if (!this.timer) {
      this.timer = window.setTimeout(() => {
        this.times = 0;
        this.timer = null;
      }, this.timeout);
    }

    this.times++;
    if (this.times <= this.limitTimes) {
      fn();
    } else if (!this.hasOutput) {
      this.hasOutput = true;
      console.error(`${this.name} 调用频率超过限制`);
    }

    if (this.times > this.tenseLimitTimes) {
      this.tense = true;
    }
  }
}
