const presetCache = new Map<string, string>();

let CDN_HOSTNAME = window["QCCDN_HOST"] || "imgcache.qq.com";

/**
 * @deprecated
 */
export const css = {
  /**
   * @deprecated
   *
   * 返回内置 CSS URL
   * @param preset 内置 CSS 名称
   */
  preset(preset: CSSPreset) {
    // 控制台把预置 CSS 版本直出到全局变量 `CSS_PRESET_VERSIONS` 中
    const presetMap = window["CSS_PRESET_VERSIONS"] || {};
    if (!presetMap[preset]) {
      console.warn("找不到预定义样式配置：%s", preset);
    }
    return presetMap[preset] || null;
  },

  /**
   * @deprecated
   *
   * 返回 CDN URL
   * @param pathname CDN 路径
   */
  cdn(pathname: string) {
    if (!pathname.startsWith("/")) {
      pathname = "/" + pathname;
    }
    return `//${CDN_HOSTNAME}${pathname}`;
  },
};

export type CSSPreset = "tea-component";
