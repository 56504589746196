// 控制台导出了 insight 作为全局变量
export const internalInsightShouldNotUsedByBusiness: InsightCore =
  window["Insight"];

export interface InsightCore {
  // core exports
  EventLevel: {
    Info: 0;
    Warn: 1;
    Error: 2;
  };
  register(name: string, meta: InsightEventMeta): InsightEventBus;
  care<T extends (...args: any) => any>(
    method: T,
    options: InsightCareOptions
  ): T;
  watch<T extends Function>(
    method: T,
    bus: InsightEventBus,
    builder: Function
  ): T;
  captureTrace(
    target: any,
    defaultMessage?: string,
    defaultStack?: string
  ): InsightTrace;
  shutdown(): void;
  on(event: string, callback: Function): void;
  off(event: string, callback: Function): void;
  emit(event: string, ...args: any[]): void;

  // export by 'error' integration
  jsErrorBus: InsightEventBus;
  promiseErrorBus: InsightEventBus;

  // export by 'network' integration
  detectNetwork(detectList: InsightNetworkDetectTask[]): void;

  // stat 自定义上报有频率控制
  stat(name: string, data: any, urgent?: boolean): void;
}

type InsightNetworkDetectTask =
  | string
  | {
      name: string;
      url: string;
      alias: string;
    };

interface InsightEventMeta {
  level: InsightEventLevel;
}

type InsightEventLevel = 0 | 1 | 2;

interface InsightEventBus {
  name: string;
  push: (event: any, urgent?: boolean) => void;
}

interface InsightTrace {
  message: string;
  stack: string;
}

interface Function {
  __insight_internal__?: boolean;
  __insight_careby__?: Function;
  __insight_careto__?: Function;
  __insight_fillto__?: Function;
  __insight_fillby__?: Function;
}

interface XMLHttpRequest {
  __insight_internal__?: boolean;
  __insight_follow__?: XHRFollowContext;
}

interface XHRFollowContext {
  id?: string;
  method?: string;
  url?: string;
  cgiTrace?: InsightTrace;
  cgiTraceOptions?: InsightCgiTraceOptions;
  sendTrace?: InsightTrace;
  sendTime?: Date;
  receiveTime?: Date;
  receiveBytes?: number;
  loadTime?: Date;
  status?: number;
  requestHeaders?: {
    [key: string]: string;
  };
  responseHeaders?: string;
  response?: string;
  aborted?: boolean;
}

interface InsightCgiTraceOptions {
  extendReport?: (xhr: XMLHttpRequest, context: XHRFollowContext) => any;
}

interface InsightCareOptions {
  capture: InsightCareCapture;
  before?: Function;
  after?: Function;
}

interface InsightCareCapture {
  (trace: InsightTrace, method: Function, error: Error): void;
}
