import React from "react";
import { _bridge } from "../bridge/_bridge";
import { internalInsightShouldNotUsedByBusiness as _insight } from "./insight";

const pageManager = _bridge("nmc/main/pagemanager");
const errorBoundaryEvent = _insight
  ? _insight.register("react-error-boundary", {
      level: _insight.EventLevel.Error,
    })
  : [];

type State = { hasError: boolean; error?: Error };
type renderProps = { error: Error; reset: () => void };

type Props = {
  render?: (props: renderProps) => React.ReactNode;
};

/**
 * 错误边界处理
 * 1. 支持全局错误边界上报
 * 2. 支持局部渲染错误边界上报，自定义错误处理 UI
 */
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.warn(errorInfo);
    const { render } = this.props;
    if (!render) {
      pageManager.renderOops("react-error-boundary");
    }
    const { message, stack } = error;
    errorBoundaryEvent.push({
      ...errorInfo,
      message: message || errorInfo.description,
      stack,
    });
  }

  render() {
    const { render, children } = this.props;
    if (this.state.hasError) {
      if (render) {
        return render({
          error: this.state.error,
          reset: () => this.setState({ hasError: false }),
        });
      }
      return null;
    }
    return children;
  }
}
