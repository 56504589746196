import React from "react";
import {
  RegionSelect,
  RegionPanel,
  RegionOption,
  RegionFlag,
} from "@tencent/tea-component";
import { SDKLoader } from "../bridge/sdk/SDKLoader";

declare global {
  interface Window {
    g_buffet_data: {
      version: string;
    };
  }
}

const isConsole = !!window?.g_buffet_data?.version;

interface RegionComponents {
  RegionSelect: typeof RegionSelect;
  RegionPanel: typeof RegionPanel;
  RegionOption: typeof RegionOption;
  RegionFlag?: typeof RegionFlag;
}

export function RegionSupport({
  children,
}: {
  children: (components: RegionComponents) => React.ReactNode;
}) {
  if (!isConsole) {
    return (
      <>{children({ RegionSelect, RegionPanel, RegionOption, RegionFlag })}</>
    );
  }

  return (
    <SDKLoader sdkNames={["menus-sdk"]}>
      {([{ regionComponents }]) => {
        if (regionComponents) {
          const { RegionSelect, RegionPanel, RegionOption, RegionFlag } =
            regionComponents;
          return children({
            RegionSelect,
            RegionPanel,
            RegionOption,
            RegionFlag,
          });
        }

        return children({
          RegionSelect,
          RegionPanel,
          RegionOption,
          RegionFlag,
        });
      }}
    </SDKLoader>
  );
}
