import { _bridge } from "./_bridge";
import { useEffect } from "react";

let postfix: string = null;

// 从 nmcConfig 中获得已经翻译的后缀
const nmcConfig = _bridge("nmcConfig");
if (nmcConfig && typeof nmcConfig.defaultTitle === "string") {
  postfix = (nmcConfig.defaultTitle as string).split(/\s*-\s*/g).pop();
}

/**
 * 设置文档标题
 * @param title 文档标题
 */
export function setDocumentTitle(title: string) {
  setTimeout(() => {
    document.title = postfix ? title + " - " + postfix : title;
  }, 1);
}

/**
 * React Hooks 声明文档标题
 * @param title 文档标题
 */
export function useDocumentTitle(title: string) {
  useEffect(() => setDocumentTitle(title), [title]);
}
