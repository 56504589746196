import { _bridge } from "../_bridge";
import { userEmitter } from "./event";

const $ = _bridge("$");
const _login = _bridge("login");

/**
 * 清除用户当前登录态，并弹出登录对话框
 */
export function login() {
  return _login.show();
}

/**
 * 清除用户当前登录态
 */
export function logout() {
  return _login.logout();
}

if ($) {
  /**
   * 用户登录态失效时触发
   */
  $(document).on("logout", () => {
    try {
      userEmitter.emit("invalidate", { source: "logout" });
    } catch (err) {
      console.error(err);
    }
  });
  $(document).on("accountChanged", () => {
    try {
      userEmitter.emit("invalidate", { source: "accountChanged" });
    } catch (err) {
      console.error(err);
    }
  });
}
