import EventEmitter from "eventemitter3";

interface InvalidateEventArgs {
  source: "accountChanged" | "logout";
}

interface UserEventTypes {
  invalidate: [InvalidateEventArgs];
  [x: string]: any[];
}

export class UserEmitter extends EventEmitter<UserEventTypes> {}

export const userEmitter = new UserEmitter();
