import { _bridge } from "./_bridge";
import { internalInsightShouldNotUsedByBusiness as _insight } from "../core/insight";
import warning from "warning";

const _tips = _bridge("tips");

export interface AppTipsBridge {
  /**
   * 向用户提示成功信息
   * @param message 提示内容
   * @param duration 持续时间
   */
  success: typeof success;

  /**
   * 向用户提示错误信息
   * @param message 提示内容
   * @param duration 持续时间
   */
  error: typeof error;

  /**
   * 想用户提示加载信息
   * @param message 提示内容
   * @param duration 持续时间
   */
  loading: typeof loading;
}

/**
 * 提供全局用户提示
 */
export const tips: AppTipsBridge = {
  success,
  error,
  loading,
};

function success(options: { message: string; duration?: number }): void;
function success(message: string, duration?: number): void;
function success(optsOrMsg: any, duration?: number): void {
  if (optsOrMsg && typeof optsOrMsg === "object") {
    _tips.success(optsOrMsg.message, optsOrMsg.duration);
  } else {
    _tips.success(optsOrMsg, duration);
  }
}

function error(options: { message: string; duration?: number }): void;
function error(message: string, duration?: number): void;
function error(optsOrMsg: any, duration?: number): void {
  let message = optsOrMsg;
  if (optsOrMsg && typeof optsOrMsg === "object") {
    message = optsOrMsg.message;
    duration = optsOrMsg.duration;
  }
  const context = _insight ? _insight.captureTrace(new Error(message)) : null;
  _tips.error(message, duration, context);
}

function loading(options: {
  message?: string;
  duration?: number;
}): { stop: () => void };
function loading(message?: string, duration?: number): { stop: () => void };
function loading(optsOrMsg: any, duration?: number): { stop: () => void } {
  let message = optsOrMsg;
  if (optsOrMsg && typeof optsOrMsg === "object") {
    message = optsOrMsg.message;
    duration = optsOrMsg.duration;
  }

  _tips.requestStart({ text: message });

  let loadingTimer = null;

  const stop = () => {
    if (loadingTimer) {
      clearTimeout(loadingTimer);
      loadingTimer = null;
      _tips.requestStop();
    }
  };

  loadingTimer = setTimeout(stop, duration || 4000);

  return { stop };
}

/**
 * @deprecated
 * 使用 `app.tips` 代替
 */
export const tip = {
  /**
   * @deprecated
   * 向用户提示成功信息
   * @param message 提示内容
   * @param duration 持续时间
   */
  success(message: string, duration?: number) {
    warning(false, "`app.tip` is deprecated. Please use `app.tips` instead.");
    _tips.success(message, duration);
  },

  /**
   * @deprecated
   * 向用户提示错误信息
   * @param message 提示内容
   * @param duration 持续时间
   */
  error(message: string, duration?: number) {
    warning(false, "`app.tip` is deprecated. Please use `app.tips` instead.");
    let context = _insight ? _insight.captureTrace(new Error(message)) : null;
    _tips.error(message, duration, context);
  },

  /**
   * @deprecated
   * 想用户提示加载信息
   * @param message 提示内容
   * @param duration 持续时间
   */
  loading(message: string, duration?: number) {
    warning(false, "`app.tip` is deprecated. Please use `app.tips` instead.");
    _tips.loading(message, duration);
  },
};
