import { _appUtil, _manager } from "../_bridge";

let loginUin = 0;
let ownerUin = 0;

const loginInfo = window.LOGIN_INFO;
if (loginInfo) {
  loginUin = loginInfo.loginUin;
  ownerUin = loginInfo.ownerUin;
}

const STO_KEY = `tea_last_prj_${loginUin}_${ownerUin}`;

export function getLastProjectId() {
  let projectId: number = Number(localStorage.getItem(STO_KEY));

  if (!projectId) {
    // 从控制台老逻辑获取
    projectId = Number(_appUtil.getProjectId());
  }

  return projectId || -1;
}

export function setLastProjectId(projectId: number) {
  localStorage.setItem(STO_KEY, String(projectId));
}

export function clearLastProjectId() {
  localStorage.removeItem(STO_KEY);
}

export function getPermitedProjectInfo() {
  return new Promise<PermitedProjectInfo>(resolve =>
    _manager.getProjects((result: any) =>
      resolve({
        isShowAll: result.isShowAll,
        projects: (result.data || []).map((item: any) => ({
          projectId: item.projectId,
          projectName: item.name,
        })),
      })
    )
  );
}

export const getPermitedProjectList = () =>
  getPermitedProjectInfo().then(x => x.projects);

export interface PermitedProjectInfo {
  /**
   * 当前用户是否有具备查看所有项目的权限
   */
  isShowAll: boolean;

  /**
   * 当前用户有权限的项目列表
   */
  projects: ProjectItem[];
}

export interface ProjectItem {
  /**
   * 项目 ID，为 0 表示默认项目
   */
  projectId: number;

  /**
   * 项目名称
   */
  projectName: string;
}
