import { _manager } from "../_bridge";

/**
 * 批量检查用户白名单
 */
export const checkWhitelistBatch = (keys: string[]) =>
  new Promise<{ [key: string]: number }>((resolve, reject) =>
    _manager.queryWhiteList(
      // params
      { whiteKey: keys },
      // onSuccess
      (result: any) => {
        let ret = {};

        for (let key of keys) {
          ret[key] = (result && result[key] && +result[key][0]) || 0;
        }

        resolve(ret);
      },
      // onError
      (error: any) => reject(Object.assign(new Error(error.msg), error || {}))
    )
  );

/**
 * 检查白名单
 */
export const checkWhitelist = (key: string) =>
  checkWhitelistBatch([key]).then(result => result[key]);
