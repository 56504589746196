import React, { useContext, useEffect, DependencyList, useRef } from "react";
import { Location } from "history";
import { useHistory } from "./history/history-context";

export interface BeforeDestroyParams {
  /**
   * 导致模块要销毁的原因
   *
   * - `navigate` 表示用户要导航到其他路由下，所以销毁当前模块
   * - `unload` 表示浏览器选项卡被刷新/关闭，所以销毁当前模块
   */
  type: "navigate" | "unload";

  /**
   * 用户即将到达的页面路由，只有在 `type` 为 `navigate` 的时候会有这个参数
   */
  url?: string;
}

/**
 * 返回 false 将取消模块销毁
 */
export type BeforeDestroyListener = (
  params: BeforeDestroyParams
) => string | false | void;

type BeforeDestroyContextProps = {
  setBeforeDestroyListener?: (listener: BeforeDestroyListener) => void;
};

export const BeforeDestroyContext = React.createContext<
  BeforeDestroyContextProps
>(null);

BeforeDestroyContext.displayName = "Tea.BeforeDestroy";

/**
 * @param listener 页面即将销毁或者跳转时调用
 * @param deps
 *
 * @example
 * ```js
function Component() {
  useBeforeDestroy(({ type, url }) => {
    return "确认离开当前页面？";
    // return 字符串时将弹出确认框：是（跳转）/否（阻止跳转）
    // return false 时将阻止跳转逻辑
  }, []);
  return <div>Component</div>
}
```
 */
export const useBeforeDestroy = (
  listener: BeforeDestroyListener,
  deps: DependencyList = []
) => {
  const history = useHistory();
  const { setBeforeDestroyListener } = useContext(BeforeDestroyContext);

  useEffect(() => {
    let passPromptHook = false;
    const confirm: BeforeDestroyListener = params => {
      const result = listener(params);
      if (typeof result === "string") {
        return window.confirm(result) as any;
      }
      return result !== false;
    };

    // history.block
    const promptHook = (location: Location) => {
      const result = confirm({
        type: "navigate",
        url: location.pathname + location.search + location.hash,
      });
      if (result) {
        passPromptHook = true;
      }
      return result;
    };
    const unblock = history.block(promptHook as any);

    // 控制台路由
    const beforeDestroyListener = params => {
      // 路由跳转时可能先经过 promptHook，此时如果到达 beforeDestroy，一定是不阻止跳转
      if (passPromptHook) {
        passPromptHook = false;
        return;
      }
      return confirm(params);
    };
    setBeforeDestroyListener(beforeDestroyListener as any);

    return () => {
      unblock();
      setBeforeDestroyListener(null);
    };
  }, [listener, ...deps]);
};
