import React from "react";
import { History } from "history";
const { useContext, useEffect } = React;

export type Confirmation = typeof import("history/DOMUtils").getConfirmation;

interface HistoryContextValue {
  history: History;
  setConfirmation: (confirmation: Confirmation) => void;
}

// 使用 Context 提供 history，以及设置 conformation 的方法
export const HistoryContext = React.createContext<HistoryContextValue>(null);

HistoryContext.displayName = "Tea.History";

/**
 * 使用适配控制台的 history（React Hooks）
 *
 * 具体使用方法请参考 http://tapd.oa.com/QCloud_2015/markdown_wikis/view/#1010103951008571667
 *
 * @param getConfirmation (experimental) 路由切换前，让用户确认
 *
 * @example
```js
import React from 'react';
import { Router, Route, Link } from 'react-router-dom';
import { useHistory } from '@tea/app';
import { CvmDetailInstance } from './components';

export function CvmDetail() {
  // 此处为 React Hooks，只能在 Function Component 中使用
  const history = useHistory();

  return (
    <Router history={history}>
      <div>
        <Link to="/cvm/detail/ins-1q2w3e4r">ins-1q2w3e4r</Link>
        <Route path="/cvm/detail/:instanceId" component={CvmDetailInstance}></Route>
      </div>
    </Router>
  );
}
```
 */
export const useHistory = (getConfirmation?: Confirmation) => {
  const { history, setConfirmation } = useContext(HistoryContext);

  // 设置提供的确认函数
  useEffect(() => {
    if (getConfirmation) {
      setConfirmation(getConfirmation);
    }
    return () => setConfirmation(null);
  }, []);

  return history;
};

// TODO 转移 Ref
// dev 模式下最外层组件使用转移 Ref 的 HOC 导致异常
export function withHistory<C extends React.ComponentClass<any>>(
  WrappedComponent: C
): C {
  function TeaHistoryHoc(props) {
    const history = useHistory();
    return React.createElement(WrappedComponent, { ...props, history });
  }
  return (TeaHistoryHoc as any) as C;
}
